import router from 'next/router'
import {useState, useEffect, useContext, createContext} from 'react'

import {User, Client, Context} from 'utils/types'
import {CLOUD_FUNCTION, COOKIE, PATH, QUERY_KEYS, API_PATH} from 'utils/constants'
import {
  onData, 
  offData, 
  callAPI, 
  signOut, 
  setCookie, 
  updateData, 
  postMessage, 
  replaceRoute, 
  signInWithUid, 
  getCurrentUser, 
  serverTimestamp, 
  insideStoreModal, 
  onIdTokenChanged, 
  callCloudFunction, 
  informOtherTabsAboutAuth, 
  listenForAuthOnOtherTabs
} from 'utils/helpers'


const AppContext = createContext({})

export const AppContextProvider = ({children}) => {
  
  const [state, setState] = useState<Context>({})
  const [authUser, setAuthUser] = useState<{
    uid: string,
    email: string,
    admin: boolean,
    client: boolean,
    displayName: string,
    emailVerified: boolean
  }>(null)

  // AUTH - listen for id token changes
  useEffect(() => {

    const unsubscribe = onIdTokenChanged(async firebaseUser => {

      if (firebaseUser) {

        const tokenResult = await firebaseUser.getIdTokenResult()

        const idToken = tokenResult?.token
        const refreshToken = firebaseUser.refreshToken
  
        const user = Boolean(tokenResult?.claims.user) // if user exists, it means it's a completed user that just signed in or completed registration
        const admin = Boolean(tokenResult?.claims.admin)
        const client = Boolean(tokenResult?.claims.client)
        const {uid, email, displayName, emailVerified} = firebaseUser
  
        // SET COOKIES
        setCookie(COOKIE.ID_TOKEN, idToken)
        setCookie(COOKIE.REFRESH_TOKEN, refreshToken)
    
        // INFORM OTHER TABS...
        if (router.pathname.includes('sign') || router.pathname === PATH.HOME || router.pathname.includes(PATH.USER_HOME)) { // auth only happens on signin/signup/home and user home (when opening already authenticated app) routes - inform other tabs
          informOtherTabsAboutAuth(uid, idToken, refreshToken, user)
        }
  
        // INFORM STORE...
        if (!admin && !client) { // ...only if user
          postMessage({setTokens: {idToken, refreshToken, user}})
        }
  
        // trigger useEffect to get user/client/admin
        setAuthUser(authUser => {
          const _authUser = {uid, email, admin, client, displayName, emailVerified}
          if (JSON.stringify(authUser) !== JSON.stringify(_authUser)) {
            return _authUser
          } else {
            return authUser
          }
        })

        // close window/tab if it was opened just to sign in
        if (window.opener) { // this shows that the window/tab was opened programmatically with window.open()
          window.close()
        }

      } else {

        setAuthUser(authUser => {
          if (authUser) {
            return null
          }
          return authUser
        })
      }
    })

    return () => {
      unsubscribe()
    }

  }, [])

  // AUTH - force refresh the id token every 30 minutes
  useEffect(() => {

    const timer = setInterval(async () => {

      const user = getCurrentUser()
      
      if (user) {
        await user.getIdToken(true)
      }
    }, 30 * 60 * 1000)

    return () => {
      clearInterval(timer)
    }

  }, [])

  // AUTH - listen to other tabs/frames for auth changes
  useEffect(() => {

    const handler: Parameters<typeof listenForAuthOnOtherTabs>[0] = ({uid, path, fromStoreModal}) => {

      if (!(path === router.pathname && fromStoreModal === insideStoreModal())) { // ignore if message sent by self
        if (uid) {
          if (!getCurrentUser()) {
            if (!insideStoreModal()) { // iframe auth is controlled by rightsize.js
              signInWithUid(uid)
            }
          }
        } else {
          if (getCurrentUser()) {
            signOut()
          }
        }
      }
    }

    const channel = listenForAuthOnOtherTabs(handler)

    return () => {
      if (channel) {
        channel.removeEventListener('message', handler)
      }
    }

  }, [])

  // AUTH - get user/client/admin
  useEffect(() => {

    if (authUser) {

      const {uid: id, email, admin, client, displayName} = authUser

      if (admin) {

        setAdmin()
        
      } else if (client) {

        onData(`clients/${id}`, dbClient => {

          if (dbClient) {

            setClient({...dbClient, id})
          }
        }, console.error)

      } else {

        onData(`users/${id}`, dbUser => {

          if (dbUser) {

            setUser({...dbUser, id})
          
          } else { // logged in with a user not yet in DB, e.g. via sign in with google or client signup

            if (router.pathname !== PATH.CLIENT_SIGN_UP) { // a CLIENT has NOT just signed up

              if (email) { // make sure this is indeed a newly registered user

                const userData = {
                  email,
                  createdOn: serverTimestamp(),
                  name: displayName || email?.split('@')[0]
                }

                updateData(`users/${id}`, userData)
              }
            }
          }
        }, console.error)
      }

    } else {

      setState(state => {

        const {admin, client, user, ...otherState} = state

        if (user || client || admin) { // user/client/admin signed out

          if (user) { // user signed out
            offData(`users/${user.id}`)
          }
    
          if (client) { // client signed out
            offData(`clients/${client.id}`)
          }

          return {...otherState}
        }

        return state
      })
    }

    function setUser(user: User) {

      setState(state => {

        if (!needsUpdate(state.user, user)) {
          return state
        }

        if (state.user) { // user has been updated

          if (!state.user.measurement && user.measurement) { // user registration completed

            callCloudFunction(
              CLOUD_FUNCTION.USER_SET_CUSTOM_CLAIMS,
              {id: user.id, claims: {user: {size: user.size, section: user.section}}}
            )
            .then(() => getCurrentUser().getIdToken(true))
            
            if (insideStoreModal()) {
              replaceRoute(PATH.STORE)
            } else {
              user.justRegistered = true
            }
          }

          if (user.measurement && state.user.size !== user.size) { // user size updated

            callCloudFunction(
              CLOUD_FUNCTION.USER_SET_CUSTOM_CLAIMS,
              {id: user.id, claims: {user: {size: user.size, section: user.section}}}
            )
            .then(() => getCurrentUser().getIdToken(true))
          }

        } else {

          if (user.name) { // user has just signed in...
            if (!user.measurement) { // ...via Google without first going through the signup
              replaceRoute(PATH.USER_SIGN_UP)
            } else if (!insideStoreModal()) {
              replaceRoute(PATH.USER_HOME_PROFILE)
            }
          }
        }

        return {
          ...state,
          user: {...user, justRegistered: user.justRegistered || state.user?.justRegistered} // so justRegistered wouldn't get lost during repeated updates - need to find out why this is called more than once
        }
      })
    }
    
    function setClient(client: Client) {

      setState(state => {

        if (!state.client && client) { // just logged in or registered
          if (authUser.emailVerified) {
            replaceRoute(PATH.CLIENT_HOME_STATS)
          } else {
            replaceRoute(PATH.ACCOUNT_VERIFY_EMAIL)
          }
        }

        if (needsUpdate(state.client, client)) {
          return {...state, client}
        }

        return state
      })
    }

    function setAdmin() {

      setState(state => {

        if (state.admin?.name === authUser.email) {

          return state

        } else {

          replaceRoute(PATH.ADMIN)
          
          return {
            ...state,
            admin: {
              name: authUser.email
            }
          }
        }
      })
    }

  }, [authUser])

  // STORE MODAL
  useEffect(() => {

    setState(state => {

      if (state.insideStoreModal === insideStoreModal()) {
        return state
      }

      return {...state, insideStoreModal: insideStoreModal()}
    })
  }, [])

  // TEAM INVITATION
  useEffect(() => {

    const id = router?.query[QUERY_KEYS.CLIENT_REF] as string

    if (id) {

      callAPI(API_PATH.INVITING_CLIENT, {id}).then(({client}: {client: Client}) => {

        setState(state => {

          const user = state.user
  
          if (!user || client.users.find(({email}) => (email === user.email) && (user.team?.clientId !== id))) {
  
            const invitingClient = {...client, id}

            if (needsUpdate(state.invitingClient, invitingClient)) {
              return {
                ...state,
                invitingClient
              }
            }
            
            return state
  
          } else {
  
            if (state.invitingClient === undefined) {
              return state
            }

            return {
              ...state,
              invitingClient: undefined
            }
          }
        })
      })

      router.replace(router.asPath, router.pathname, {shallow: true})
    }
  }, [])
  
  // RECOMMENDATION
  useEffect(() => {

    const {recommendation} = router.query

    if (recommendation) {

      setState(state => {

        if (JSON.stringify(recommendation) !== JSON.stringify(state.recommendation)) {

          return {
            ...state,
            recommendation: JSON.parse(recommendation as string)
          }
        }

        return state
      })
    }
  }, [])

  const needsUpdate = (before: User | Client, after: User | Client): boolean => {
  
    const {createdOn: createdOnBefore, updatedOn: updatedOnbefore, ...pureBefore} = before || {}
    const {createdOn: createdOnAfter, updatedOn: updatedOnAfter, ...pureAfter} = after || {}

    if (JSON.stringify(pureBefore) === JSON.stringify(pureAfter)) {
      return false
    }

    return true
  }

  return (
    <AppContext.Provider value={{...state}}>
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext<Context>(AppContext)
