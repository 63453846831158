import Link from 'next/link'
import router from 'next/router'
import styled from '@emotion/styled'
import {useState, useEffect} from 'react'
import useTranslation from 'next-translate/useTranslation'

import {useAppContext} from 'utils/context'
import {PATH, ICON, I18N} from 'utils/constants'
import {Select, getSelectOptions} from './Select'
import {Logo, Modal, Button, LanguageSelector} from 'components'
import {closeModal, signOut, deleteAuthUser} from 'utils/helpers'


export interface Props {
  back?: boolean
}

const Container = styled.div`
  top: 0;
  width: 100%;
  z-index: 100;
  margin: 0 auto;
  position: sticky;
  background-color: ${({theme}) => theme.colorWhite};
`
const Contents = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  max-width: ${({theme}) => theme.content};
  padding: ${({theme}) => theme.size_1_5} ${({theme}) => theme.contentPadding};
  @media (max-width: ${({theme}) => theme.breakpoint}) {
    margin-bottom: 0;
    & button {
      display: none;
    }
  }
`
const Controls = styled.div`
  display: flex;
  gap: ${({theme}) => theme.gap};
`
const CloseButton = styled(Button)`
  display: flex !important;
`

const PageHeader = ({back}: Props) => {

  const {t} = useTranslation()
  
  const [prompt, setPrompt] = useState('')
  const {user, client, admin, insideStoreModal} = useAppContext()
  const [state, setState] = useState({
    name: '',
    logoLink: '',
    minimal: true,
    options: {} as {[key: string]: string}
  })

  useEffect(() => {

    setState({
      name: admin?.name || 
            client?.name || 
            (user?.name || '') + (user?.team?.name ? ' | ' + user.team.name : '') || 
            t(`${I18N.COMMON}:header.anonymous`),
      options: insideStoreModal && user?.measurement
        ? {profile: t(`${I18N.COMMON}:action.profile`), signOut: t(`${I18N.COMMON}:action.signOut`)}
        : {signOut: t(`${I18N.COMMON}:action.signOut`)},
      minimal: router.pathname.includes(PATH.USER_SIGN_UP) || insideStoreModal,
      logoLink: (() => {
        switch(router.pathname) {
          case PATH.LEGAL_TERMS:
          case PATH.LEGAL_PRIVACY:
            return user
              ? user.measurement
                ? PATH.USER_HOME_PROFILE
                : PATH.USER_SIGN_UP
              : client
                ? PATH.CLIENT_HOME_STATS
                : PATH.HOME
          case PATH.USER_HOME_SHOES:
          case PATH.USER_HOME_PROFILE:
          case PATH.USER_HOME_MEASUREMENT:
          case PATH.USER_HOME + '/[tab]':
            return insideStoreModal
              ? PATH.STORE 
              : ''
          case PATH.CLIENT_HOME_TEAM:
          case PATH.CLIENT_HOME_DOCS:
          case PATH.CLIENT_HOME_STATS:
          case PATH.CLIENT_HOME_SIZES:
          case PATH.CLIENT_HOME_PROFILE:
          case PATH.CLIENT_HOME + '/[tab]':
          case PATH.CLIENT_SIGN_OUT:
          case PATH.USER_SIGN_UP_CREATE_ACCOUNT:
          case PATH.STORE:
          case PATH.ADMIN:
            return ''
          case PATH.USER_SIGN_UP:
            return user || insideStoreModal
              ? ''
              : PATH.HOME
          default:
            return insideStoreModal
              ? PATH.USER_SIGN_UP
              : PATH.HOME
        }
      })()
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client, admin, insideStoreModal])

  const handleOption = (option: string) => {

    switch (option) {

      case 'profile':
        router.push(PATH.USER_HOME_PROFILE)
        break
      
      default: // sign out
        if (user && !user.name) { // anonymous user
          setPrompt(t(`${I18N.COMMON}:header.anonymousSignOutWarning`))
        } else {
          signOut()
        }
    }
  }

  const closeSignOutAndDeleteModal = () => {

    setPrompt('')
  }

  const confirmSignOutAndDelete = () => {

    closeSignOutAndDeleteModal()
    deleteAuthUser()
  }

  const {name, options, minimal, logoLink} = state

  return (
    <>
      <Container>
        <Contents>
          {back
            ? <Button secondary onClick={router.back}>{t(`${I18N.COMMON}:action.back`)}</Button>
            : <>
                {logoLink // it's important to do it like this because on navigation via Link getServerSideProps are not called, so I cannot redirect based on current user/client
                  ? <Link href={logoLink} passHref><a><Logo /></a></Link>
                  : <Logo admin={!!admin} />
                }
                <Controls>
                  {admin || client || user?.measurement
                    ? <>
                        {admin ? null : <LanguageSelector />}
                        <Select
                          borderless
                          value={name}
                          placeholder={name} // needed for Cypress tests
                          isSearchable={false}
                          options={getSelectOptions(options, false)}
                          onValueChange={handleOption}
                        />
                      </> 
                    : minimal
                      ? <LanguageSelector />
                      : <>
                          <Button borderless onClick={() => router.push(PATH.DOCS)}>{t(`${I18N.COMMON}:header.docs`)}</Button>
                          <Button borderless onClick={() => router.push(PATH.PRICING)}>{t(`${I18N.COMMON}:header.pricing`)}</Button>
                          <LanguageSelector />
                          <Button onClick={() => router.push(PATH.CLIENT_SIGN_UP)}>{t(`${I18N.COMMON}:action.startFreeTrial`)}</Button>
                          <Button secondary onClick={() => router.push(PATH.CLIENT_SIGN_IN)}>{t(`${I18N.COMMON}:action.signIn`)}</Button>
                        </>
                  }
                  {insideStoreModal
                    ? <CloseButton 
                        id='close'
                        icon={ICON.CLOSE} 
                        secondary 
                        onClick={closeModal}
                      >{' '}</CloseButton> 
                    : null
                  }
                </Controls>
              </>
          }
        </Contents>
      </Container>
      {prompt ?
        <Modal 
          lighterBackdrop={insideStoreModal}
          onSubmit={confirmSignOutAndDelete} 
          onDismiss={closeSignOutAndDeleteModal} 
          primaryButton={t(`${I18N.COMMON}:action.signOutAndDelete`)}
        >
          {prompt}
        </Modal> : null
      }
    </>
  )
}

export default PageHeader
